import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'
import { TimelockResponse } from '../types/API'
import { SupportedChainId } from '../constants/chainInfo'

interface Timelock {
  mimicId: string
  namespace?: string
  timelocks: any[]
}

const useGeneralTimelocks = (chainIds: number[]) => {
  const { callApi } = useApi()

  const fetchTimelocks = async (chainId: number): Promise<TimelockResponse[]> => {
    try {
      const response = await callApi<EnvironmentResponse>(`/relayer-executor/timelocks/${chainId}`)
      return (response || []).map((timelock) => ({
         ...timelock,
         chainId, 
       }))
    } catch (error) {
      console.error(`Error fetching timelocks for chainId ${chainId}:`, error)
      return []
    }
  }

  const fetchAllTimelocks = async (): Promise<TimelockResponse[]> => {
    const chainIds = Object.values(SupportedChainId)
    const promises = chainIds.map(chainId => fetchTimelocks(chainId))
    const results = await Promise.allSettled(promises)


    const successfulResults = results
      .filter(result => result.status === 'fulfilled')
      .map(result => (result as PromiseFulfilledResult<TimelockResponse[]>).value)
      .flat()

    return successfulResults
  }

  return useQuery<TimelockResponse[]>({
    queryKey: ['timelocks', chainIds],
    queryFn: fetchAllTimelocks,
    refetchOnWindowFocus: false,
  })
}

export { useGeneralTimelocks }
