import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Toaster } from 'react-hot-toast'
import { Section, Loader, selectStyles } from '../utils/styles'
import { ButtonColor, AllNotificationsContainer } from '../components/structure/styles'
import NotificationCard from '../components/structure/notifications/NotificationCard'
import useAdminNotifications from '../hooks/useAdminNotifications'

const NOTIFICATIONS_LIMIT = 50

const Overview = () => {
  const {
    notifications,
    isLoading: notificationsLoading,
    isFetching,
    dismissedNotifications,
    dismissNotification,
    showNotificationsAgain,
    fetchNextPage,
  } = useAdminNotifications({ limit: NOTIFICATIONS_LIMIT })

  const [selectedSeverity, setSelectedSeverity] = useState<string | null>(null)
  const [selectedService, setSelectedService] = useState<string | null>(null)

  // Filtrar notificaciones según los valores seleccionados de severidad y servicio
  const filteredNotifications = notifications.filter((notif) =>
    !dismissedNotifications.has(notif._id) &&
    (!selectedSeverity || notif.severity === selectedSeverity) &&
    (!selectedService || notif.service === selectedService)
  )

  // Obtener severidades únicas
  const getUniqueSeverities = (): { value: string | null; label: string }[] => {
    const uniqueSeverities = [...new Set(notifications.map((notif) => notif.severity))]
    const severityOptions = uniqueSeverities.map((severity) => ({ value: severity, label: severity }))
    return [{ value: null, label: 'See all' }, ...severityOptions]
  }

  // Obtener servicios únicos
  const getUniqueServices = (): { value: string | null; label: string }[] => {
    const uniqueServices = [...new Set(notifications.map((notif) => notif.service))]
    const serviceOptions = uniqueServices.map((service) => ({ value: service, label: service }))
    return [{ value: null, label: 'See all' }, ...serviceOptions]
  }

  return (
    <Section>
      <Toaster position="top-right" />
      <br />
      <FiltersContainer>
        <SelectContainer>
          <Select
            styles={selectStyles}
            options={getUniqueSeverities()}
            onChange={(option) => setSelectedSeverity(option?.value || null)}
            isClearable
            placeholder="Filter by severity"
          />
        </SelectContainer>
        <SelectContainer>
          <Select
            styles={selectStyles}
            options={getUniqueServices()}
            onChange={(option) => setSelectedService(option?.value || null)}
            isClearable
            placeholder="Filter by service"
          />
        </SelectContainer>
      </FiltersContainer>
      <br />
      {notificationsLoading ? (
        <Loader />
      ) : (
        <AllNotificationsContainer>
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map((notification) => (
              <NotificationCard
                key={notification._id}
                notification={notification}
                onDismiss={dismissNotification}
              />
            ))
          ) : (
            <ButtonColor onClick={showNotificationsAgain}>Show dismissed notifications</ButtonColor>
          )}
          {notifications.length % NOTIFICATIONS_LIMIT === 0 && (
            <ButtonColor disabled={isFetching} onClick={() => fetchNextPage()}>
              {isFetching ? 'Loading...' : 'Load more notifications'}
            </ButtonColor>
          )}
        </AllNotificationsContainer>
      )}
    </Section>
  )
}

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`

const SelectContainer = styled.div`
  max-width: 300px;
`

export default Overview
