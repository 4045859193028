import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import toast, { Toaster } from 'react-hot-toast'
import { ContainerTable, Flexbox, Loader, ButtonViolet } from '../../utils/styles'
import { useAuth } from '../../context/AuthContext'
import { useApi } from '../../hooks/useApi'
import Calendar from '../Calendar'

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

interface Report {
  startDate: string
  createdAt: string
  endDate: string
  updatedAt: string
  fileName: string
  mimicId: string
  _id: string
}

const DATE_FORMAT = 'MMMM DD, YYYY, HH:mm'

const Reports: React.FC = () => {
  const { callApi } = useApi()
  const { user, refreshToken, logout } = useAuth()
  const [data, setReports] = useState<Report[] | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startMonth, setStartMonth] = useState<Date | undefined>(new Date())
  const params = useParams()

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
    if (date) {
      setStartMonth(date)
    }
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
  }

  const fetchReport = async () => {
    try {
      const reports = await callApi<Report[]>(`/reports/environments/${params.id}`)
      setReports(reports)
    } catch (error) {
      console.error('An error occurred while fetching the reports:', error)
    }
  }

  useEffect(() => {
    fetchReport()
  }, [])

  const handleDownload = async (id: string, fileName: string) => {
      const token = localStorage.getItem('token')
      const url = `${BASE_URL}/reports/environments/${id}/download`

      try {
        const toastId = toast.loading('Your report is being downloaded...');
        const response = await axios.get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${user?.token}`,
          },
          responseType: 'blob',
        })
        saveAs(new Blob([response.data]), fileName)

        toast.success('Your report was successfully downloaded', {
          id: toastId,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 403) {
            toast("❗️ You don't have permissions to download this report")
          } else if (error?.response?.status === 401) {
            const tokenRefreshed = await refreshToken()
            if (tokenRefreshed) {
              return await handleDownload(id, fileName)
            } else {
              toast.error('Session expired. Please log in again.')
              logout()
              return null
            }
          } else {
            toast("❗️ We couldn't download the report")
          }
        } else {
          console.error('There was an error trying to download the report:', error)
        }
      }
    }

  const generateReport = async () => {
    if (!startDate || !endDate) {
      toast('❗️You have to complete both dates before generating the report')
      return
    }

    const adjustedStartDate = moment
      .utc(startDate)
      .set({
        hour: startDate.getHours(),
        minute: startDate.getMinutes(),
        second: 0,
        millisecond: 0,
      })
      .toISOString()

    const adjustedEndDate = moment
      .utc(endDate)
      .set({
        hour: endDate.getHours(),
        minute: endDate.getMinutes(),
        second: 0,
        millisecond: 0,
      })
      .toISOString()

    try {
      const data = {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        mimicId: params.id,
      }

      await callApi('/jobs/report', data, 'POST')
      fetchReport()
    } catch (error: any) {
      console.error('There was an error generating the report:', error)
    }
  }

  return (
    <ReportSection>
      <Toaster position="top-right" />
      <>
        <Flex>
          <Calendar label="From date" selectedDate={startDate} withHour={true} onChange={handleStartDateChange} />
          <Calendar
            label="To date"
            selectedDate={endDate}
            startMonth={startMonth}
            withHour={true}
            onChange={handleEndDateChange}
          />
          <ButtonViolet onClick={generateReport}>Generate Report</ButtonViolet>
        </Flex>
      </>
      {data ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>From (UTC)</th>
                <th>To (UTC)</th>
                <th>Created At (UTC)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice()
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map((item, index) => (
                  <tr key={index}>
                    <td className="accent">{item.fileName}</td>
                    <td>{moment.utc(item.startDate).format(DATE_FORMAT)} hs</td>
                    <td>{moment.utc(item.endDate).format(DATE_FORMAT)} hs</td>
                    <td>{moment.utc(item.createdAt).format(DATE_FORMAT)} hs</td>
                    <td>
                      <button className="violet" onClick={() => handleDownload(item._id, item.fileName)}>
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </ContainerTable>
        </>
      ) : (
        <Loader />
      )}
    </ReportSection>
  )
}

const ReportSection = styled.div`
  margin: 50px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  table {
    input {
      margin-bottom: 0 !important;
      height: 26px !important;
    }
  }
`

const Flex = styled(Flexbox)`
  align-items: center;
  justify-content: center;
  button {
    margin-top: 0;
  }
`

export default Reports
