import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import Decimal from 'decimal.js'
import { useApi } from './useApi'

interface MonitorData {
  [tokenAddress: string]: {
    [address: string]: string
  }
}

export interface BalanceSummary {
  address: string
  balance: string
  source: { address: string; balance: string }[]
}

interface FetchMonitorsParams {
  id: string
  limit: number
  page: number
  filter?: Record<string, unknown>
  chainId: number
}

const useSimpleMonitor = (
  id: string | undefined,
  chainId: number,
  page: number = 1,
  limit: number = 50,
  filter?: Record<string, unknown>,
  refetchInterval: number = 0
): {
  summary: BalanceSummary[]
  data: MonitorData | undefined
  tokenList: string[]
  isLoading: boolean
  error: unknown
  isRefetching: boolean
  isFetched: boolean
} => {
  const { callApi } = useApi()

  const fetchMonitors = async (): Promise<MonitorData> => {
    if (!id) return {}

    const params = {
      mimicId: id,
      chainId,
      limit,
      page,
      ...filter,
    }

    console.log(params)
    return await callApi<MonitorData>('/token-monitor/monitors/balances', removeEmptyValues(params))
  }

  const { data, isLoading, error, isRefetching, isFetched } = useQuery<MonitorData>({
    queryKey: ['simpleMonitor', id, page, filter],
    queryFn: fetchMonitors,
    staleTime: 1000,
    refetchInterval: refetchInterval,
    enabled: !!id,
  })

  const { summary, tokenList } = useMemo(() => {
    if (data) {
      return calculateTotalBalances(data)
    }
    return { summary: [], tokenList: [] }
  }, [data])

  return { summary, data, tokenList, isLoading, error, isRefetching, isFetched }
}

function calculateTotalBalances(data: MonitorData): { summary: BalanceSummary[]; tokenList: string[] } {
  const summary: BalanceSummary[] = []
  const tokenList: string[] = []

  for (const [tokenAddress, addresses] of Object.entries(data)) {
    let totalBalance = new Decimal(0)
    const source: { address: string; balance: string }[] = []

    for (const [address, balance] of Object.entries(addresses)) {
      totalBalance = totalBalance.plus(new Decimal(balance))
      source.push({ address, balance })
    }

    if (!tokenList.includes(tokenAddress)) {
      tokenList.push(tokenAddress)
    }

    summary.push({
      address: tokenAddress,
      balance: totalBalance.toFixed(),
      source,
    })
  }

  return { summary, tokenList }
}

const removeEmptyValues = (obj) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === "") {
      delete newObj[key]
    }
  })

  return newObj
}

export default useSimpleMonitor
